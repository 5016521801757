.cardCategory {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 30px;
  background-color: #fff;
  position: absolute;
  bottom: -15px;
  left: 15px;
  padding: 8px 15px;
}

.cardRating {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  background-color: #30263e;
  position: absolute;
  top: 2px;
  left: 1px;
  padding: 5px 15px;
  padding-left: 10px;
}

.cardActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 10px 15px;
  padding-left: 10px;
  gap: 5px;
}

.serviceCard {
  width: 100%;
  text-decoration: none;
  color: gray;
  min-width: 300px;
  max-width: 400px;
  position: relative;
  gap: 10px;
}

.ratingContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 25px;
  background-color: #30263e;
  position: absolute;
  bottom: -15px;
  left: 20px;
  padding: 3px 10px;
  padding-left: 8px;
}

.cardRatingServiceCard {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  background-color: #30263e;
  position: absolute;
  top: -18px;
  left: 0px;
  padding-top: 20px;
  padding-left: -10px;
}

.serviceInfo {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2px;
}

.cardPricing {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  /* padding-top: 20px; */
  margin: 30px
}

.priceVal {
  color: #30263e;
  font-weight: 500;
  margin: 0;
}