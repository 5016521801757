.dashboardContainer {
  background: #f5f4fa !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: flex-start;
}

.servicesContainer {
  background: #f5f4fa !important;
}

.dashboardContainerRow {
  width: 90%;
  margin: 10px auto;
  margin-top: 110px;
}

.loadingSpinnerEnd {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-height: 300px;
}

.dashboardMidSection {
  position: relative;
  overflow-y: auto;
  height: calc(100vh - 50px);
  scroll-behavior: smooth;
}

.topRow,
.storiesRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 20px;
  overflow-x: auto;
}

.topRow::-webkit-scrollbar,
.storiesRow::-webkit-scrollbar {
  display: none;
}

.leftButton,
.rightButton {
  background: #30263e;
  /* background: linear-gradient(45deg, rgb(52, 152, 219), rgb(187, 134, 252)); */
  backdrop-filter: blur(10px);
  /* border: 1px solid rgb(56, 55, 55); */
  border: none !important;
  border-radius: 50%;
  padding: 10px;
  height: 30px;
  width: 30px;
  cursor: pointer;
  z-index: 1;
  color: white;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.6); */
  transition: background 0.3s ease;
  font-size: 8px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.leftButton:hover,
.rightButton:hover {
  background: #f5f4fa !important;
  color: #30263e;
}

.leftButton {
  left: 10px;
}

.rightButton {
  right: 10px;
  transform: rotate(180deg);
}

.topRow:hover .leftButton,
.topRow:hover .rightButton {
  display: block;
}

.navButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.storiesRow {
  margin: 0 20px;
  margin-right: 40px;
  padding-bottom: 20px;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #ccc;
}

.blogRow {
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 15px;
  margin: 20px 0;
  overflow-y: auto;
}

@media screen and (max-width: 900px) {
  .dashboardContainerRow {
    margin-top: 0.5rem;
    /* z-index: 200*/
    margin-bottom: 15rem;
  }

  .dashboardContainer {
    /* z-index: 200; */
    padding-bottom: 10rem;
  }

  .mobileHide {
    display: none !important;
  }

  .mobileVisible {
    width: 100% !important;
    min-width: 100% !important;
  }

  .storiesRow {
    width: 100%;
    margin-left: 0;
  }
}

/* In your DashboardPage.module.css or a separate CSS file */
.profile-images {
  display: flex;
  flex-wrap: wrap;
}

.profile-image-container {
  position: relative;
  width: 80px;
  /* Adjust the width as needed */
  height: 80px;
  /* Adjust the height as needed */
  margin: 8px;
  cursor: pointer;
}

.profile-image {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 50%;
  background: linear-gradient(45deg,
      #ffc700,
      #ff4500);
  /* Adjust the colors as needed */
  border: 4px solid transparent;
  /* Adjust the border thickness as needed */
  transition: border-width 0.3s ease;
}

.profile-image-container:hover::before {
  border-width: 8px;
  /* Adjust the border thickness on hover as needed */
}

.carouselContainer {
  /*  */

  border-radius: 12px;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  gap: 10px;
  padding: 20px;
}

.carouselContainer>h2 {
  font-weight: bold;
}

.carouselContainer>h2>span {
  text-decoration: underline;
  margin-left: 100px;
}

/* CardSlider.css */
.cardSlider {
  display: flex;
  overflow-x: auto;
  /* flex-direction: column; */
  padding: 16px;
  gap: 16px;

  /* background: linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%) !important; */
  /* background: linear-gradient(0deg, #f5f4fa 0%, #d8f3ff 60.48%, #ecd8ff 100%); */
  border-radius: 10px;
}

.cardSliderContainer {
  background: linear-gradient(0deg, #f5f4fa 0%, #d8f3ff 60.48%, #ecd8ff 100%);
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  width: 95%;
}

.cardSliderHeader {
  border-radius: 20px;
  padding-left: 2rem;
  padding-right: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardSliderHeader>.seeAll {
  text-decoration: underline;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  color: black;
}

.cardContainer {
  flex: 0 0 auto;
}

.custom-card {
  width: 300px;
  /* Set fixed width for each card */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.cardSlider::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.cardSlider {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.card-slider::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.card-slider {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.dashboardSection1 {
  position: -webkit-sticky;
  /* For Safari */
  position: sticky;
  top: 100px;
  /* Adjust this value based on your header's height or desired distance from the top */
  z-index: 5;
  /* Ensure it's higher than other elements if needed */
  border: none;
  /* Adjusted based on your original styling */
  padding: 0px 0.5rem;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
    /* Adjust this value for desired movement */
  }

  100% {
    transform: translateY(0);
  }
}

.imageAnimation {
  animation: moveUpDown 2s infinite;
  /* Adjust duration and timing as needed */
}

/* STORIES CSS */

.App {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Karla", sans-serif;
  height: 100%;
  flex-direction: column;
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
}

.stories {
  filter: drop-shadow(0 10px 20px #aaa);
}

@media only screen and (max-width: 768px) {
  .App {
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
  }
}

.cardWrapper {
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  margin: 0 auto !important;
  padding: 0 !important;
  overflow: hidden;
  background-color: transparent !important;
  width: 98%;
  cursor: pointer !important;
  border: none !important;
  border-bottom: 1px solid #d8f3ff;
}

.cardImageWrapper {
  position: relative;
  width: 100%;
}

.coverImage {
  width: 100%;
  object-fit: cover;
  max-height: 500px;
  min-height: 365px;
  border-radius: 25px !important;
}

.rightOverlay {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 10%;
  padding: 10px;
  /* background: rgba(0, 0, 0, 0.3); */
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.profileInfoContainer {
  margin-bottom: auto;
  text-align: center;
}

.profileDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.profilePic {
  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  border: 2px solid white;
  object-fit: cover;
}

.profilePicPlaceholder {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: white;
  border: 2px solid white;
}

.profileName {
  font-weight: bold;
  color: #30263e;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.postDate {
  font-size: 12px;
  color: #ccc;
  margin-top: 5px;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.iconBtn {
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.countOnIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(92.54deg, #8800ff, #00b2ff);
  color: #fff;
  font-weight: bold;
  z-index: 2;
  width: 18px;
  height: 18px;
  font-size: 12px;
  border-radius: 50%;
  position: absolute;
  top: -6px;
  right: -6px;
}

.serviceListing {
  display: flex;
  flex-direction: column;
  width: 95%;
  margin: auto;
  gap: 1rem;
  margin-bottom: 1rem;
}

.bottomIconsContainer {
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 10px;
}

.carousel {
  /* border: 1px solid red; */
  width: 80% !important;
}

.carousel button {
  border: 1px solid red !important;
  background: rgba(255, 255, 255, 0.25) !important;
  border: none !important;
  padding: 10px !important;
  cursor: pointer !important;
  border-radius: 50% !important;
  transition: background 0.3s ease !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(0px) !important;
  -webkit-backdrop-filter: blur(0px) !important;
  border: 1px solid rgba(255, 255, 255, 0.18) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 40px !important;
  height: 40px !important;
}

.carousel button:hover {
  background: rgba(255, 255, 255, 0.5) !important;
}

.carousel svg {
  width: 30px !important;
  height: 30px !important;
  font-weight: bold !important;
  stroke-width: 2.5px !important;
}

.carousel svg::after {
  content: none !important;
  display: none !important;
  height: 0 !important;
  width: 0 !important;
}

.svgIcon::after {
  border: 1px solid red !important;
  content: none !important;
  display: none !important;
  height: 0 !important;
  width: 0 !important;
}

.carousel .slick-next::after {
  border: 1px solid red !important;
  content: none !important;
  display: none !important;
  height: 0 !important;
  width: 0 !important;
}

.postElementCounter {
  display: flex;
  position: absolute;
  top: 50%;
  /* margin-left: 1rem; */
  /* right: 10px; */
  right: 50%;
  font-weight: bold;
  font-size: 12px;
  color: #ccc;
  margin-top: 5px;
  padding: 0 5px;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50000;
}

.profileInfoStrip {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0.5rem; */
  border-radius: 10px;
  /* background-color: #f1f1f1; */
}

.profileDetailsDrawer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.iconCapsule {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
}

.icon {
  width: 24px;
  height: 24px;
  filter: grayscale(100%);
  position: relative;
}

.icon.liked {
  filter: grayscale(0%);
}

.likeBtn {
  /* border: 1px solid red; */
  /* position: relative; */
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-left: 80%;
}

.serviceDesc {}

.serviceDesc>span {
  /* font-size: 18px; in rem */
  font-size: 1rem;
  color: #30263e;
}

.userProfilePic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.commentInput {
  border: 2px solid #ccc !important;
  border-radius: 10px !important;
}

.commentInput:focus {
  border: 2px solid #ccc !important;
  border-radius: 10px !important;
}

.commentInput::placeholder {
  color: #ccc;
}

.commentInput:hover {
  border: 2px solid #ccc !important;
  border-radius: 10px !important;
}

.commentBtn {
  background-color: #30263e !important;
  border-radius: 35px !important;
  margin-left: 80% !important;
  color: white !important;
  padding: 5px 10px !important;
  font-size: 15px !important;
  font-weight: bold !important;
}

.commentBtn:hover {
  background-color: #4e4dff !important;
}

.navButton {
  background: rgba(255, 255, 255, 0.25) !important;
  border: none !important;
  padding: 10px !important;
  cursor: pointer !important;
  border-radius: 50% !important;
  transition: background 0.3s ease !important;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37) !important;
  backdrop-filter: blur(0px) !important;
  -webkit-backdrop-filter: blur(0px) !important;
  border: 1px solid rgba(255, 255, 255, 0.18) !important;
}

.navButton:hover {
  background: rgba(255, 255, 255, 0.5) !important;
}

.svgIcon {
  width: 30px !important;
  height: 30px !important;
  font-weight: bold !important;
  stroke-width: 2.5px !important;
}

/* Container for stories and buttons */
.storiesContainer {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 0.25rem;
}

.storiesRow {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  overflow-x: auto;
  padding: 10px;
  margin: 0 50px;
  border: none !important;
  border-radius: 90px;
  padding-right: 40px;
  padding-left: 40px;
}

.storiesRow::-webkit-scrollbar {
  display: none;
}

.leftButtonStory,
.rightButtonStory {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: #30263e;
  color: white;
  border: none;
  border-radius: 50%;
  padding: 10px;
  height: 40px;
  width: 40px;
  cursor: pointer;
  z-index: 1;
  display: none;
}

.storiesContainer:hover .leftButtonStory,
.storiesContainer:hover .rightButtonStory {
  display: block;
}

.leftButtonStory {
  left: 40px;
}

.rightButtonStory {
  right: 40px;
}

.leftButtonStory:hover,
.rightButtonStory:hover {
  background: #f5f4fa;
  color: #30263e;
}

.storyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3000; /* Ensure it's above everything */
}

.modalContent {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 600px;
  width: 100%;
  z-index: 4000; /* Ensure it's above the overlay */
}



.closeButton {
  margin-top: 10px;
  padding: 8px 12px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 40px;
}

.showMore {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 10px;
}




/*  */



                      /*                  */