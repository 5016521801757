.extraButtonSectionLogin {
  display: flex;
  justify-content: center;
  align-items: center;
}

.extraButtonSectionQuickLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.loginPopUp .MuiDialog-paperWidthSm {
  background: linear-gradient(151deg, #ecd8ff 0%, #d8f3ff 60.52%) !important;
}
.otherLoginSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.loginButtons {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
}

.loginButtonsQC {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
  flex-direction: column;
}

.loginButtonsMain {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  flex-direction: column;
}

.loginButtons_ {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.extraButtonSection > button {
  color: black;
  text-decoration: underline;
}

.preFixAntdClass {
  margin: 0px 10px;
  font-size: large;
}

@media (max-width: 768px) {
  .loginButtons {
    width: 70vw;
  }
  .loginButtonsQC {
    width: 70vw;
  }
  .loginButtonsMain {
    width: 70vw;
  }
  .loginButtons_ {
    width: 70vw;
  }
  .extraButtonSectionQuickLogin {
    width: 70vw;
  }
}

@media (max-width: 500px) {
  .loginButtons {
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
  }
  .loginButtonsQC {
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
  }
  .loginButtonsMain {
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
  }
  .loginButtons_ {
    flex-direction: column;
    width: 80%;
    margin: 0 auto;
  }
  .extraButtonSectionQuickLogin {
    width: 80%;
    margin: 0 auto;
  }
}

.buttonQC {
  border-radius: 20px;
  background-color: #4e4dff ;
  font-weight: 500;
}

.buttonQC :hover {
  border-radius: 20px;
  border : 1px solid #30263e;
  font-weight: 500;
  color: #30263e;
  height: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}
